<template>
  <b-modal
      v-model="showMessage"
      centered
      title="系统消息"
      size="lg"
      title-class="font-18"
      hide-footer
  >
    <div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0">公告标题：</label>
        <div class="col-sm-10 p-0 flexList">{{form.noticeTitle}}</div>
      </div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0">公告类型：</label>
        <div class="col-sm-10 p-0 flexList" v-if="form.noticeType == 1">通知</div>
        <div class="col-sm-10 p-0 flexList" v-if="form.noticeType == 2">公告</div>
      </div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0">通知日期：</label>
        <div class="col-sm-10 p-0 flexList">
            {{form.createTime}}
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <label class="mb-0">是否已读：</label>
        <div class="col-sm-10 p-0 flexList" id="sfyd">
          <i class="iconfont text-success icon-yidu" v-if="form.sfyd == 1"></i>
          <i class="iconfont text-warning icon-weidu"  v-else></i>
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <div class="col-sm-10 p-0 flexList">
          {{form.noticeContent}}
        </div>
      </div>
      <div class="flexList check-distri mb-3" v-if="form.link">
        <div class="col-sm-10 p-0 flexList">
          <a :href="form.link" target="_blank">
            <button
                type="button"
                class="btn btn-info h30 w-md mr-3"
            >
              前往处理
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showMessage = false"
      >
        关闭
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getWebNoticeDetails, updateWebNoticeSfyd} from '@/api/notice/notice.js'
export default {
  name: "notice",
  data(){
    return {
      form: {},
      showMessage: false,
    }
  },
  methods:{
    show(noticeId, callback){
      updateWebNoticeSfyd(noticeId).then(res1=>{
        if (res1.status){
            getWebNoticeDetails(noticeId).then(res=>{
            if(res.status){
              this.showMessage = true
              this.form = res.data
              if (callback){
                callback()
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
#sfyd .iconfont{
  font-size:25px;
}
</style>