import store from '@/state/store.js';
import {
  userInfo
} from "@/api/userInfo"
import {
  uploadFiles
} from "@/api/index"
import {
  CodeToText
} from "element-china-area-data"; //省市区解码
import {
  Loading
} from 'element-ui';
import {
  DictionaryCode
} from "@/libs/dictionary.js"
import {
  getDict
} from "@/api/common/dict.js";
import {
  getStore,
  setStore,
  removeStore
} from './storage';
import Cookies from 'js-cookie';
import $ from 'jquery'
export const formatDateDetailsToDay = (dateStr) => {
  let date = new Date(dateStr)
  return date.getFullYear() + "-" + formatInteger(date.getMonth() + 1) + "-" + date.getDate()
}

let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  loading.close()
}

export const formatInteger = (num, digit) => {
  var numSting = String(num)
  if (numSting.length >= digit) {
    return numSting;
  } else {
    var prefix = ""
    for (let i = 0; i < digit - numSting.length; i++) {
      prefix += "0"
    }
    return prefix + numSting
  }
}

export const formatDateTime = (datea) => {
  let date = new Date(Date.parse(datea));
  //alert(date);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  // var second = date.getSeconds();
  // second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute;
}


export const format = (dateStr, fmt) => {

  // dateStr = dateStr.replace(/-/g, "/")
  let date;
  try {
    date = new Date(dateStr);
  } catch {
    return dateStr
  }

  let o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours() % 24 == 0 ? 24 : date.getHours() % 24, //小时
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

export const isTimeInArea = (nowDate, start, end) => {
  start = start.replace(/-/g, "/")
  end = end.replace(/-/g, "/")
  try {
    let start_time = new Date(start).getTime();
    let end_time = new Date(end).getTime();
    let now_time = nowDate.getTime();
    return now_time >= start_time && now_time <= end_time;
  } catch {
    return false;
  }
}

export const regionCode = (data) => {
  let arr = []
  arr.push(data.slice(0, 2) + "0000")
  arr.push(data.slice(0, 4) + "00")
  arr.push(data)
  return arr
}
export const changeOption = (event, form, kv) => {
  // for (let k in kv) {
  //   let v = kv[k];
  //   form[v] = event[k];
  // }
  form[kv[0]] = event.name;
  form[kv[1]] = event.value;
}

//地址选中后数据处理
export const handleChange = (event, form, name, code) => {
  for (const key in name) {
    switch (name[key]) {
      case "S":
        form[key] = CodeToText[event[0]];
        break;

      case "s":
        form[key] = CodeToText[event[1]];
        break;

      case "q":
        form[key] = CodeToText[event[2]];
        break;

      case "Ss":
        form[key] =
          CodeToText[event[0]] + "/" + CodeToText[event[1]];
        break;
      case "Ssq":
        form[key] =
          CodeToText[event[0]] +
          "/" +
          CodeToText[event[1]] +
          "/" +
          CodeToText[event[2]];
        break;

      default:
        break;
    }
  }

  for (const key in code) {
    switch (code[key]) {
      case "S":
        form[key] = event[0].slice(0, 2);
        break;

      case "s":
        form[key] = event[1].slice(2, 4);
        break;

      case "q":
        form[key] = event[2];
        break;

      default:
        break;
    }
  }
}

//验证手机号码
export const verifyPhone = (tel) => {
  var pattern = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
  let res = {
    isRight: true,
    errorMess: ''
  };
  if (tel == '') {
    res.isRight = false;
    res.errorMess = '手机号为空';
    return res;
  }
  if (!pattern.test(tel)) {
    res.isRight = false;
    res.errorMess = '手机格式不正确';
    return res;
  }
  return res
}
//验证邮箱
export const verifyEmail = (email) => {
  var pattern = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  let res = {
    isRight: true,
    errorMess: ''
  };
  if (email == '') {
    res.isRight = false;
    res.errorMess = '邮箱地址为空';
    return res;
  }
  if (!pattern.test(email)) {
    res.isRight = false;
    res.errorMess = '邮箱格式不正确';
    return res;
  }
  return res
}

//验证密码
export const verifyPassword = (password) => {
  var pattern = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;

  let res = {
    isRight: true,
    errorMess: ''
  };
  if (password == '') {
    res.isRight = false;
    res.errorMess = '密码为空';
    return res;
  }
  if (!pattern.test(password)) {
    res.isRight = false;
    res.errorMess = '密码格式不正确(6-12位数字、字母、特殊符号2种以上组合)';
    return res;
  }
  return res
}



//数据回显时转换
export const boundDefaultData = (sjzd, fromData, data) => {
  for (let k in data) {
    let list = data[k].split(',')
    for (let lineData of sjzd[k]) {
      for (const m of list) {
        if (fromData[m] && fromData[m] === lineData.value) {
          fromData[m + "_"] = lineData;
        }
      }

    }
  }
}


export const itemConfig = (tableName, datas, configs, newDict) => {
  let data = datas[tableName];
  let config = configs[tableName];
  if (config == undefined) {
    newDict[tableName] = data;
    return;
  }

  let list = []
  for (let item of data) {
    let newData = {}
    newData['name'] = item[config['name']]
    newData['value'] = item[config['value']]
    newData['details'] = item

    if (item.children) {
      itemConfig('children', item, config, newData)
    }
    list.push(newData)
  }
  newDict[tableName] = list;
}

//数据字典配置
export const changeName = (data) => {
  let newData = {}
  for (const key in data) {
    itemConfig(key, data, DictionaryCode, newData)

  }
  return newData
}

// 获取数据字典
export const getDictionary = (...list) => {
  return new Promise((resolve, reject) => {
    let newList = []
    let data = {}
    list.forEach(v => {
      if (store.state.dict.Dict[v]) {
        data[v] = store.state.dict.Dict[v]
      } else {
        newList.push(v)
      }
    })
    if (newList.length == 0) {
      data = changeName(data)
      resolve(data)
    } else {
      getDict(newList.join(",")).then((res) => {
        if (res.status) {
          for (const key in res.data) {
            data[key] = res.data[key]
            store.dispatch("dict/saveDict", res.data);
          }
          data = changeName(data)
          resolve(data)
        } else {
          reject(res)
        }
      });
    }

  })
}

// 区域码转换
export const regionChange = (formData, list) => {
  for (const name of list) {
    if (formData[name]) {
      let arr = regionCode(formData[name])
      formData[name + '_'] = arr
    }

  }

}

//退出登录
export const logOut = () => {
  removeStore("accessToken");
  removeStore("userInfo");
  removeStore("requestTime");
  // Cookies.set('userInfo', '');
  // Cookies.set("locking", "0");
  store.dispatch("auth/logOut");

  window.location = Vue.prototype.API_URL + "/yethan/logout/cas";
}

//倒计时
var mytimer = null;
export const resetCode = (second, ksFun, jsFun) => {
  mytimer = setInterval(function() {
    second -= 1;
    if (second > 0) {
      if (typeof ksFun == 'function') {
        ksFun()
      }
    } else {
      clearInterval(mytimer);
      if (typeof jsFun == 'function') {
        jsFun()
      }
    }
  }, 1000);
}

export const clearMyTimer = ()=>{
  clearInterval(mytimer);
}

//上传文件校验
export const checkFile = (file, astrict) => {
  let obj = {
    status: false,
    message: ''
  }
  if (file.size > astrict.size) {
    obj.message = '文件大小超出限制'
    return obj
  }
  let fileType = file.name.split(".")[file.name.split(".").length - 1];
  if (astrict.ext.indexOf(fileType) == -1) {
    obj.message = "文件类型不合法"
    return obj;
  }
  obj.status = true
  return obj
}


//文件大小转换单位
export const unitsChange = (size) => {
  if ((size / 1024) < 1) {
    return size + 'b'
  } else if ((size / 1024 / 1024) < 1) {
    return Math.round(size / 1024) + 'Kb'
  } else if ((size / 1024 / 1024 / 1024)) {
    return Math.round(size / 1024 / 1024) + 'Mb'
  }
}

// 判断图片像素
export const checkImgPx = (width, height, file) => {
  let _URL = window.URL || window.webkitURL;
  let img = new Image();
  img.src = _URL.createObjectURL(file);
  return new Promise((resolve) => {
    img.onload = function() {
      resolve(width == img.width && height == img.height)
    };
  });
}



// // 导出页面为PDF格式
// import html2canvas from "html2canvas"
// import JSPDF from "jspdf"
// export const ExportSavePdf = (htmlTitle, id) => {
//   var element = document.getElementById(id)
//   html2canvas(element, {
//     logging: false
//   }).then(function (canvas) {
//     var pdf = new JSPDF("p", "mm", "a4") // A4纸，纵向
//     var ctx = canvas.getContext("2d")
//     var a4w = 170;
//     var a4h = 257 // A4大小，210mm x 297mm，四边各保留20mm的边距，显示区域170x257
//     var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
//     var renderedHeight = 0

//     while (renderedHeight < canvas.height) {
//       var page = document.createElement("canvas")
//       page.width = canvas.width
//       page.height = Math.min(imgHeight, canvas.height - renderedHeight) // 可能内容不足一页

//       // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
//       page.getContext("2d").putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
//       pdf.addImage(page.toDataURL("image/jpeg", 1.0), "JPEG", 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加图像到页面，保留10mm边距

//       renderedHeight += imgHeight
//       if (renderedHeight < canvas.height) {
//         pdf.addPage()
//       } // 如果后面还有内容，添加一个空页
//       // delete page;
//     }
//     let currentTime = new Date().getTime()
//     pdf.save(htmlTitle + currentTime)
//   })
// }



// htmlToPdf.js
// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import Vue from "vue";
export const ExportSavePdf = (htmlTitle, ids, type, isPrint, closeId) => {
  startLoading()
  let idList = ids.split(',')
  let index = 0
  var title = htmlTitle //DPF标题
  let PDF
  if (type == "1") {
    PDF = new JsPDF("l", "mm", "a4"); // l 表示横向导出；true表示压缩pdf，否则文件会很大
  } else {
    PDF = new JsPDF("p", "mm", "a4"); //竖版
  }
  Canvas(idList, index, PDF, title, type, isPrint, closeId)
}
const Canvas = (idList, index, PDF, title, type, isPrint, closeId) => {
  if (!idList[index]) {
    if (closeId) {
      $("#" + closeId).css("display", "none")
    }
    endLoading()
    if (isPrint === false) { //预览
      // document.getElementById("iframe123").src = PDF.output('datauristring'); //动态替换当前页中的iframe
      // PDF.output('dataurlnewwindow');
      PDF.autoPrint();
      // PDF.output('dataurlnewwindow');
      window.open(PDF.output('bloburl'), '_blank');
    } else { //下载
      PDF.save(title + '.pdf')
    }
    return false
  }
  if (index != 0) {
    PDF.addPage()
  }
  let height = Math.floor($('#' + idList[index]).outerHeight());
  let width = Math.floor($('#' + idList[index]).outerWidth());
  if (type == "1") {
    let divisor = Math.floor(width / 297 * 210)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  } else {
    let divisor = Math.floor(width / 210 * 297)
    let num = Math.ceil(height / divisor);
    $("#" + idList[index]).css({
      height: num * divisor + "px",
    });
  }
  html2Canvas(document.querySelector('#' + idList[index]), {
    allowTaint: true,
    taintTest: false,
    useCORS: true,
    y: 0, // 对Y轴进行裁切
    dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
    scale: 4 //按比例增加分辨率 
  }).then(function(canvas) {
    // $("#" + idList[index]).css({
    //   height: 'auto',
    // }); //改回原来高度
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    let pageHeight = Math.floor(contentWidth / 210 * 297)
    let leftHeight = contentHeight
    let position = 0
    let imgWidth
    let imgHeight
    let pageData = canvas.toDataURL('image/jpeg', 1.0)
    if (type == "1") {
      imgWidth = 297;
      imgHeight = (297 / contentWidth) * contentHeight;
      pageHeight = Math.floor(contentWidth / 297 * 210)
    } else {
      imgWidth = 210;
      imgHeight = (210 / contentWidth) * contentHeight;
    }
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        if (type == "1") {
          position -= 210
        } else {
          position -= 297
        }
        if (leftHeight > 0) {
          PDF.addPage()
        }
      }
    }

    index++
    Canvas(idList, index, PDF, title, type, isPrint, closeId)

  })
}

export const getBase64 = (img_url) => {
  function toBase64(image) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, image.width, image.height);
    const base64 = canvas.toDataURL('image/png');
    return base64;
  }

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // 解决跨域
    image.crossOrigin = '*';
    image.src = img_url + '?v=' + Math.random();  // 解决图片URL缓存问题
    image.onload = () => {
      resolve(toBase64(image));
    };
  });
}

//图片地址转base64
export const getBase64Image = (url) => {
  var image = new Image()
  image.crossOrigin = 'Anonymous' // 支持跨域图片
  image.src = url // 处理缓存
  // image.setAttribute('crossOrigin', 'anonymous');
  image.onload = () => {
    var base64 = drawBase64Image(image)
    return base64
  }

}

const drawBase64Image = (img) => {
  var canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  var ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  var dataURL = canvas.toDataURL('image/png')
  return dataURL
}

/**
 *
 * @param fn {Function}   实际要执行的函数
 * @param delay {Number}  延迟时间，也就是阈值，单位是毫秒（ms）
 *
 * @return {Function}     返回一个“去弹跳”了的函数
 */
export const debounce = (fn, delay) => {

  // 定时器，用来 setTimeout
  var timer

  // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
  return function() {

    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
    clearTimeout(timer)

    // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
    // 再过 delay 毫秒就执行 fn
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}



//获取用户信息
export const getUserInfo = () => {
  return new Promise((resolve, reject) => {
    userInfo().then((res) => {
      if (res.status) {
        store.dispatch("auth/saveInfo", {
          data: res.data
        });
        resolve(res.data)
      }
    });
    // let data = store.state.auth.currentUser
    // if (!data) {
    //   userInfo().then((res) => {
    //     if (res.status) {
    //       store.dispatch("auth/saveInfo", {
    //         data: res.data
    //       });
    //       resolve(res.data)
    //     }
    //   });
    // } else {
    //   resolve(data)
    // }

  })

}

/**
 * 判断访问类型是PC端还是手机端
 */
export const isMobile = () => {
  var userAgentInfo = navigator.userAgent;

  var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

  var mobile_flag = false;

  //根据userAgent判断是否是手机
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }

  var screen_width = window.screen.width;
  var screen_height = window.screen.height;

  //根据屏幕分辨率判断是否是手机
  if (screen_width < 500 && screen_height < 800) {
    mobile_flag = true;
  }

  return mobile_flag;
}



//文件上传
export const uploadFile = (file, fileRule, astrict) => {
  return new Promise((resolve, reject) => {
    let result = checkFile(file, astrict);
    if (result.status) {
      uploadFiles(fileRule, file).then((res) => {
        if (res.status) {
          resolve(res)
        }
      });
    } else {
      reject(result)
    }
  })
}
