import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import vco from "v-click-outside"
import moment from 'moment'
import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import ElementUI from 'element-ui';
import {
  Loading
} from "element-ui"
import {
  getStore,
  setStore,
} from '@/libs/storage';
import {
  formatDate,
  formatDateDetails
} from "./libs/utils";
import "@/assets/css/iconfont/iconfont.css"
import "@/assets/scss/app.scss";
import 'element-ui/lib/theme-chalk/index.css';
import {
  initFirebaseBackend
} from './helpers/firebase/authUtils';
import {
  configureFakeBackend
} from './helpers/fakebackend/fake-backend';
import yzNotice from "./components/notice/notice.js";

import {
  ExportSavePdf,
  getBase64Image,
  isMobile
} from "@/libs/common"
import {getRequest} from "./libs/axios";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

// let base = "http://222.209.91.218:8082";
// let fileBase = "http://222.209.91.218:8085";
let base = "https://ersuserapi.jdpta.com";
let fileBase = "https://ersfile.jdpta.com/";
// let fileBase = "http://192.168.0.101:80";
// let base = "http://127.0.0.1:8099";
let baseKw = "http://127.0.0.1:8099";
// let base = "http://192.168.0.124:8099"; c47d0279241fd3222d74e53d46995687652663488bc45e09  4091324365723840

Vue.prototype.API_URL_KW = baseKw;
Vue.prototype.API_URL = base;
Vue.prototype.FILE_URL = fileBase;
Vue.prototype.getStore = getStore
Vue.prototype.setStore = setStore
Vue.prototype.formatDate = formatDate;
Vue.prototype.isMobile = isMobile;
Vue.prototype.formatDateDetails = formatDateDetails;

Vue.prototype.getFileUrl = function(fileId, isBase64=false) {
  if (isBase64){
    return getRequest(`/yethan/file/download/base64/${fileId}`, {isBase64: true})
  } else {
    return `${base}/yethan/file/download/${fileId}?ytoken=${getStore('accessToken')}`
  }
}
Vue.prototype.$moment = moment
Vue.prototype.ExportSavePdf = ExportSavePdf
Vue.prototype.getBase64Image = getBase64Image
Vue.prototype.openLoading = function () {
  const loading = this.$loading({ // 声明一个loading对象
    lock: true, // 是否锁屏
    text: '加载中', // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.8)', // 背景颜色
    target: '.el-table, .table-flex, .region', // **需要遮罩的区域，这里写要添加loading的选择器**
    fullscreen: false,
    customClass: 'loadingclass' // **遮罩层新增类名，如果需要修改loading的样式**
  })
  setTimeout(function () { // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close(); // 关闭遮罩层
  }, 5000)
  return loading;
}



// 下载文件


import ImgDialog from "@/components/img/imgDialog";
import YzUpload from '@/components/form/yzUpload/yzUpload.js'



Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(yzNotice)
Vue.use(ImgDialog)
Vue.use(YzUpload)

Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(ElementUI)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
