import {
    getRequest
} from "@/libs/axios";
import store from '@/state/store.js';
import Vue from 'vue'
import {len} from "vuelidate/lib/validators/common";

// 获取
export const getDict = (...params) => {

    return getRequest(`/yethan/public/dict/${params}`);
};



// 公共字典数据

// 字典转换器
const coverDict = function (items, conf, i = 0) {
    let res = [];
    items.forEach((item) => {
        let resItem = {};
        resItem["value"] = item[conf[i][0]];
        resItem["name"] = item[conf[i][1]];
        resItem["details"] = item;
        if (conf && conf.length - 1 > i) {
            resItem["children"] = coverDict(item.children, conf, i + 1);
        }
        res.push(resItem);
    });
    return res;
};
// 多个字典转换器
const coverDicts = function (items, ...conf) {
    if (items) {
        return coverDict(items, conf);
    }
    return items;
};

// 自定义字典配置对应的方法
const dictFunc = {
    ssjg: [
        function () {
            return getRequest("/yethan/data/org/easyList");
        },
        ["jgdm", "jgmc"],
    ],
    ssdw: [
        function () {
            return getRequest("/yethan/data/orgDepartment/easyList");
        },
        ["bmdm", "bmmc"],
    ],
    bm: [
        function () {
            return getRequest("/yethan/data/orgUnits/easyList");
        },
        ["dwdm", "dwmc"],
    ],
    // 收费账户
    sh: [
        function () {
            return getRequest("/yethan/pay/merchant/easyList");
        },
        ["shbm", "shmc"],
    ],
    // 考试大类
    ksdl: [
        function () {
            return getRequest("/yethan/register/mainType/easyList");
        },
        ["lbbm", "lbmc"],
    ],
    // 考试二级类别
    ejlb: [
        function () {
            return getRequest("/yethan/register/secondType/easyList");
        },
        ["flbm", "flmc"],
    ],
    ksxx: [
        function () {
            return getRequest("/yethan/register/examRecord/easylist");
        },
        ["ksbmbh", "ksmc"],
    ],
    // 科目
    km: [
        function () {
            return getRequest("/yethan/register/subject/easyList");
        },
        ["kmdm", "kmmc"],
    ],
    gddl: [
        function () {
            return getRequest("/yethan/workOrderType/big/list");
        },
        ["sid", "sortName"],
    ],
    gdxl: [
        function () {
            return getRequest("/yethan/workOrderType/small/list");
        },
        ["tid", "typeName"],
    ],
    zgxlm: [
        function () {
            return new Promise(r => {
                getRequest("/yethan/userInfo/userInfoEducation").then(res => {
                    if (res.status) {
                        res.data.forEach(v => {
                            v.name = v.byyxxhdw + v.xlmc + '(' + (v.xlshzt == 0 ? '待审' : v.xlshzt == 1 ? '审核通过' : '审核未通过') + ')'
                        })
                        r(res)
                    }
                });
            })
        },
        ["xlm", "xlmc"],
    ]

};

const coverConfig = {
    dict_xzqh: [
        ["ssdm", "ssmc"],
        ["djsdm", "djsmc"],
        ["qxdm", "qxmc"],
    ],
    dict_mz: ["mzm", "mzmc"],
    dict_xb: ["xbm", "xbmc"],
    dict_zzmm: ["zzmmm", "zzmmjc"],
    dict_hyzt: ["hyztm", "hyztmc"],
    dict_xl: ["xlm", "xlmc"],
    dict_xw: ["xwm", "xwmc"],
    dict_sfzjlx: ["sfzjlxm", "sfzjlxmc"],
    dict_gatqw: ["gatqwm", "gatqwmc"],
    dict_gjdq: ["zfdm3", "gjdqmc"],
    dict_xyzj: ["xyzjm", "xyzjmc"],
    dict_xxfs: ["xxfsm", "xxfsmc"],
    dict_jkzk: ["jkzkm", "jkzkmc"],
};



const getDict1111 = (...list) => {
    return new Promise((resolve) => {
        let newList = []
        let data = {}
        list.forEach(v => {
            if (store.state.dict.Dict[v]) {
                data[v] = store.state.dict.Dict[v]
            } else {
                newList.push(v)
            }
        })
        if (newList.length == 0) {
            data = changeName(data)
            resolve(data)
        } else {
            getDict(newList.join(",")).then((res) => {
                if (res.status) {
                    for (const key in res.data) {
                        data[key] = res.data[key]
                        store.dispatch("dict/saveDict", res.data);
                    }
                    data = changeName(data)
                    resolve(data)
                } else {
                    reject(res)
                }
            });
        }
    })
}

const AllFun = ()=>{

}



// 获取字典都要走的方法
export const getDicts = (...dicts) => {
    return new Promise((resolve) => {
        let resData = {};
        // if (Vue.prototype.dict_xzqh_list && dicts.indexOf("dict_xzqh")>=0) {
        //     resData['dict_xzqh'] = coverDicts(Vue.prototype.dict_xzqh_list, ...coverConfig['dict_xzqh']);
        //     dicts = ['none']
        // }
        getRequest(`/yethan/public/dict/${dicts.join(",")}`).then((res) => {
            for (let k in coverConfig) {
                if (res.data[k]) {
                    if (k == "dict_xzqh") {
                        resData[k] = coverDicts(res.data[k], ...coverConfig[k]);
                        Vue.prototype.dict_xzqh_list = res.data[k]
                    } else {
                        resData[k] = coverDicts(res.data[k], coverConfig[k]);
                    }
                }
            }
            let func = [];
            let keys = [];
            let covers = [];
            dicts.forEach((item) => {
                if (dictFunc[item]) {
                    func.push(dictFunc[item][0]());
                    keys.push(item);
                    covers.push(dictFunc[item][1]);
                }
            });
            if (func.length == 0) {
                resolve(resData);
            }
            Promise.all(func).then((result) => {
                for (let i in keys) {
                    resData[keys[i]] = coverDicts(result[i].data, covers[i]);
                    resData = Object.assign(resData, {
                        yhlb: [{
                                name: "机构用户",
                                value: "org"
                            },
                            {
                                name: "管理员用户",
                                value: "admin"
                            },
                        ],
                        gdzycd: [{
                                name: "普通",
                                value: "normal"
                            },
                            {
                                name: "重要",
                                value: "important"
                            },
                        ],
                    });
                    resolve(resData);
                }
            });
        });
    });
};

export const getDictSrc = params => {
    return getRequest("/yethan/dict/tableInfo/dictPage", params);
};


export const getUnits = function (params) {
    return getRequest("/yethan/data/orgUnits/easyList", params)
}