export const state = {
    Dict: {},
}

export const mutations = {
    SET_Dict(state, obj) {
        for (const key in obj) {
            state.Dict[key] = obj[key]
        }
    },
}

export const getters = {
    loggedIn(state,key) {
        return !!state.Dict[key]
    },
}

export const actions = {
    deleteDict({
        commit
    }) {
        commit('SET_Dict', null)
    },

    saveDict({
        commit
    }, obj) {
        commit('SET_Dict', obj)
    }
}
