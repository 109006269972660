<template>
  <input
    id="upload_id"
    type="file"
    :accept="astrict.ext"
    multiple="multiple"
    @change="uploadFile"
    style="display: none"
  />
</template>

<script>
import { getUploadLimit } from "@/api/index";
import { getDictionary, checkFile, unitsChange } from "@/libs/common";
import { uploadFiles } from "@/api/common/public";
import $ from "jquery";

export default {
  name: "yzUpload",
  data() {
    return {
      module: "",
      callback: null,
      astrict: {
        ext: "",
        size: "",
        text: "",
      },
    };
  },
  methods: {
    getLimit(module) {
      return new Promise((resolve, reject) => {
        getUploadLimit({ module: module }).then((res) => {
          if (res.status) {
            this.astrict["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
            this.astrict["size"] = res.data.sizeLimit;
            let size = unitsChange(res.data.sizeLimit);
            this.astrict["text"] =
              "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
            resolve();
          }
        });
      });
    },
    startUpload(module, callback) {
      this.module = module;
      this.callback = callback;
      this.getLimit(module).then(() => {
        $("#upload_id").click();
      });
    },
    uploadFile(ev) {
      var files = ev.target.files;
      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        uploadFiles(this.module, files[i]).then((res) => {
          if (res.status) {
            const fileInfo = res.data[0];
            this.callback(fileInfo);
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
