import {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest
} from "@/libs/axios";



// 获取用户基本信息
export const userInfo = params => {
    return getRequest("/yethan/userInfo", params);
};
// 修改用户基本信息
export const updateUserInfo = params => {
    return putRequest("/yethan/userInfo/save", params, false);
};
// 修改用户联系电话
export const updateUserphone = params => {
    return patchRequest("/yethan/userInfo/saveMobilePhone", params);
};
// 修改用户邮箱
export const updateUserEmail = params => {
    return patchRequest("/yethan/userInfo/saveEmail", params);
};
// 修改用户密码
export const updateUserPassword = params => {
    return patchRequest("/yethan/userInfo/savePassword", params);
};
// 修改弱密码
export const updateStrongPassword = params => {
    return patchRequest("/yethan/userInfo/savePasswordStrong", params);
};
//考生用户修改手机号码校验短信验证码
export const checkPhoneCode = params => {
    return getRequest(`/yethan/checkMobileCode`, params);
  };



// 更新家庭成员信息
export const updateUserFamily = params => {
    return putRequest("/yethan/userInfo/saveUserInfoFamily", params);
};
// 查询家庭成员信息
export const getUserFamily = params => {
    return getRequest("/yethan/userInfo/userInfoFamily", params);
};
// 删除家庭成员信息
export const deleteUserFamily = params => {
    return deleteRequest("/yethan/userInfo/deleteUserInfoFamily/" + params);
};

// 更新个人工作经历信息
export const updateUserWork = params => {
    return putRequest("/yethan/userInfo/saveUserInfoWork", params);
};
// 查询工作经历信息
export const getUserWork = params => {
    return getRequest("/yethan/userInfo/userInfoWork", params);
};
// 删除工作经历信息
export const deleteUserWork = params => {
    return deleteRequest("/yethan/userInfo/deleteUserInfoWork/" + params);
};

//  更新个人教育经历
export const updateUserEdu = params => {
    return putRequest("/yethan/userInfo/saveUserInfoEducation", params);
};
// 查询个人教育经历
export const getUserEdu = params => {
    return getRequest("/yethan/userInfo/userInfoEducation", params);
};
// 查询个人学信网教育经历
export const getXXWEdu = params => {
    return getRequest("/yethan/userInfo/userEducationOfficial", params);
};
// 删除个人教育经历
export const deleteUserEdu = params => {
    return deleteRequest("/yethan/userInfo/deleteUserInfoEducation/" + params);
};

// 上传用户证件照
export const saveStandardPhoto = params => {
    return postRequest("/yethan/userInfo/saveStandardPhoto", params);
};
// 上传身份证证件照
export const saveIdentificationPhoto = params => {
    return postRequest("/yethan/userInfo/saveIdentificationPhoto", params);
};

// 获取用户证件照
export const getStandardPhoto = params => {
    return getRequest("/yethan/userInfo/userInfoPhoto", params);
};


// 保存学历证明
export const saveXLZM = params => {
    return putRequest("/yethan/userInfo/saveUserInfoEducationXlzm", params);
};