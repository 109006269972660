import axios from 'axios';
import Vue from 'vue';
import {
  getStore,
  setStore,
  removeStore
} from './storage';
import router from '../router/index.js';
import {
  Message
} from 'element-ui';
import {
  Loading
} from 'element-ui';
import {
  logOut,
  debounce
} from "./common"
import Cookies from 'js-cookie';
// 统一请求路径前缀
// let base = 'http://dev.ix2.com';
// let base = 'http://192.168.0.108:8080';
// let fileBase = 'http://192.168.0.105:8080';
let base;
let fileBase;
let baseKw;
let v = new Vue();
v.$nextTick(function () {
  base = Vue.prototype.API_URL;
  baseKw = Vue.prototype.API_URL_KW;
  fileBase = Vue.prototype.FILE_URL;

})

let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  loading.close()
}

let needLoadingRequestCount = 0

const tryCloseLoading = () => {
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    debounce(tryCloseLoading, 300)()
    // endLoading()
  }
}

// 超时设定
axios.defaults.timeout = 50000;
//请求前拦截
axios.interceptors.request.use(config => {
  let time = new Date().getTime()
  let oldTime = Number(getStore("requestTime"))
  let passTime = (time - oldTime)
  // 带token
  if (config.headers.ytoken && oldTime && passTime > 7200000) {
    removeStore("requestTime");
    logOut()
    return
    // router.push('/login').then(() => {
    //   location.reload()
    // })
  } else {
    if (config.showLoading === undefined || config.showLoading) {
      showFullScreenLoading()
    }

  }
  return config;
}, err => {
  tryHideFullScreenLoading()
  router.push('/login')
  return Promise.resolve(err);
});

// http response 拦截器
axios.interceptors.response.use(response => {
  tryHideFullScreenLoading()
  const data = response.data;
  if (data.code == "A0230") {
    logOut()
    // router.push('/login').then(() => {
    //   location.reload()
    // })
  }
  // 根据返回的code值来做不同的处理(和后端约定)
  // http的code
  switch (response.code) {
    case 401:
      // 未登录 清除已登录状态 清除锁屏状态
      Cookies.set('userInfo', '');
      Cookies.set("locking", "0");
      removeStore("accessToken");
      removeStore("userInfo");

      if (router.history.current.name != "login") {
        if (data.message !== null) {
          Message.error(data.message);
        } else {
          Message.error("未知错误，请重新登录");
        }
        router.push('/login');
      }
      break;
    case 403:
      // 没有权限
      if (data.message !== null) {
        Message.error(data.message);
      } else {
        Message.error("未知错误");
      }
      break;
    case 500:
      // 错误
      if (data.message !== null) {
        Message.error(data.message);
      } else {
        Message.error("未知错误");
      }
      router.replace("./pages/error-500")
      break;
    default:
      return data;
  }

  return data;
}, (err) => {
  // 返回状态码不为200时候的错误处理
  tryHideFullScreenLoading()
  if(error.message.includes('timeout')){
    // 判断请求异常信息中是否含有超时timeout字符串
    Message.error('请求超时，请稍后再试')
    return Promise.reject(error);          // reject这个错误信息
  }
  Message.error('网络连接失败，请稍后再试')
  return Promise.reject(error);          // reject这个错误信息
});

export const deleteRequest = (url, params) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${base}${url}`,
      params: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const getRequest = (url, params, showLoading) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    if (params && (params.isExport === 'true' || params.isExport === true)) {
      window.open(getRequestUrl(url, params), "_blank")
      resolve({
        status: false
      })
      return
    }
    axios({
      method: 'get',
      url: `${base}${url}`,
      params: params,
      showLoading: showLoading,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const getRequestUrl = (url, params) => {
  // openLoading();
  let accessToken = getStore('accessToken');
  params['ytoken'] = accessToken
  return base + url + "?" + coverParamsToQuery(params);
};

export const postRequest = (url, params, showLoading) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: params,
      showLoading: showLoading,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const downloadRequest = (url, params) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${base}${url}`,
      params: params,
      headers: {
        'ytoken': accessToken
      },
      responseType: 'blob'
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

// 原始upload不可用增加的
export const uploadFile = (url, file, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append('file', file);
    if (params) {
      console.log(params);
      formData.append('params', params);
    }
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
}

export const postFormRequest = (url, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const postFormJsonRequest = (url, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};


export const putRequest = (url, params, showLoading) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${base}${url}`,
      showLoading: showLoading,
      data: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const postBodyRequest = (url, params) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      params: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const importRequest = (url, params) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      params: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};


export const uploadFileDbf = (url, file, ksbmbh) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('ksbmbh', ksbmbh);
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
}

export const uploadFileCustom = (url, files, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append('files', files);
    for (let k in params) {
      formData.append(k, params[k]);
    }
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
}

export const uploadFileRequest = (url, params) => {
  let accessToken = getStore('accessToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${Vue.prototype.base}${url}`,
      params: params,
      headers: {
        'ytoken': accessToken,
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};

/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url
 * @param {*} params
 */
export const getNoAuthRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${base}${url}`,
      params: params
    }).then(res => {
      commonMessageHandler(resolve, res, false)
    }).catch(err => {
      reject(err.data)
    });
  })
};
export const getNoAuthRequestKw = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${baseKw}${url}`,
      params: params
    }).then(res => {
      commonMessageHandler(resolve, res, false)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const getDictList = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}` + `/yethan/dict`,
      params: params
    }).then(res => {
      commonMessageHandler(resolve, res, false)
    }).catch(err => {
      reject(err.data)
    });
  })
};

export const postNoAuthRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: params,
      headers: {}
    }).then(res => {
      commonMessageHandler(resolve, res, false)
    }).catch(err => {
      reject(err.data)
    });
  })
};
// 公共的信息处理器，方便管理

const commonMessageHandler = (resolve, res, needAuth) => {
  let res_json = {}
  if (res.code !== undefined) {
    if (res.code == "00000") {
      let newtime = new Date().getTime()

      setStore('requestTime', JSON.stringify(newtime))
      res_json["status"] = true;
      res_json["data"] = res.data;
      res_json["message"] = res.message;
      let {
        total,
        pageNum,
        pageSize
      } = res
      if (total) {
        res_json["total"] = total
      }
      if (pageNum) {
        res_json["pageNum"] = pageNum
      }
      if (pageSize) {
        res_json["pageSize"] = pageSize
      }
    } else {
      res_json["status"] = false;
      v.$message.error(res.message);
      if (needAuth) {
        // window.localStorage.clear()
        // v.$router.push("/")
      }
    }
    if (res.code == "401") {
      window.localStorage.clear()
      window.location.href = '/'
    }

  } else {
    console.log(res);
    // 加入判断，仅支持相关的文件流内容请求
    if (res.type === "application/pdf" || res.type === "application/vnd.ms-excel" || res.type == "text/xml") {
      res_json["status"] = true;
      res_json["data"] = res;
    }

  }

  resolve(res_json);
}


export const postRequestCaptcha = (url, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: params,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'ytoken': accessToken
      }
    }).then(res => {
      resolve(res);
    }).catch(() => {});
  })
};

export const patchRequest = (url, params) => {
  let accessToken = getStore("accessToken");
  return new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${base}${url}`,
      data: params,
      headers: {
        'ytoken': accessToken
      }
    }).then(res => {
      commonMessageHandler(resolve, res, true)
    }).catch(err => {
      reject(err.data)
    });
  })
};
